<div class="filters">
  <div class="filters-basic filters-basic d-flex align-items-center justify-content-end">
    <button class="btn btn-outline-primary filters-btn me-2 mb-0 p-0"
            placement="top"
            (click)="toggleDropdownFilters = !toggleDropdownFilters"
            [ngbTooltip]="'Filters'" *ngIf="dropdownFilters.length > 0">
      <app-svg name="filter"></app-svg>
    </button>

    <div class="filters-search position-relative flex-grow-1" *ngIf="!hideSearch">
      <input type="text"
             class="form-control filters-search-control pe-5"
             [placeholder]="'Search ' + searchPlaceholder"
             [(ngModel)]="searchValue"
             [disabled]="disableSearch"
             (input)="search()">

      <span class="position-absolute filters-search-icon cursor-pointer" (click)="clearSearch()" *ngIf="searchValue">
        <app-svg name="close"></app-svg>
      </span>

      <span class="position-absolute filters-search-icon cursor-pointer" (click)="search(true)">
        <app-svg name="search"></app-svg>
      </span>
    </div>
  </div>

  <!-- Dropdowns -->
  <div class="filters-dropdowns mt-3" *ngIf="dropdownFilters.length > 0 && toggleDropdownFilters">
    <div class="d-flex flex-column flex-sm-row w-100 justify-content-end">
      <div class="filters-dropdown mb-3 ps-0"
           [ngClass]="{'ps-sm-3': i !== 0}"
           *ngFor="let dropdown of dropdownFilters; let i = index">

        <div class="d-flex align-items-center justify-content-between">
          <label class="form-label pe-none mb-1">{{ dropdown.title }}</label>

          <span role="button" class="form-text text-danger mb-1"
                (click)="clearFilter(dropdown)" *ngIf="dropdown.selected && dropdown.selected.id">
              Clear
          </span>
        </div>

        <ng-select [items]="dropdown.items"
                   [ngModel]="dropdown.selected"
                   [searchable]="false"
                   (ngModelChange)="dropdown.selected = $event; emitFilters()"
                   bindLabel="label">
        </ng-select>
      </div>
    </div>
  </div>
</div>
