import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModalModule} from "@ng-bootstrap/ng-bootstrap";

import {DeleteModalComponent} from './delete-modal/delete-modal.component';
import {UploadThumbnailModalComponent} from './upload-thumbnail-modal/upload-thumbnail-modal.component';
import {ImageCropperModule} from "ngx-image-cropper";
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import {QuizModalComponent} from "./quiz-modal/quiz-modal.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SvgComponent} from "../svg/svg.component";

@NgModule({
  declarations: [
    DeleteModalComponent,
    UploadThumbnailModalComponent,
    ConfirmModalComponent,
    QuizModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    ImageCropperModule,
    ReactiveFormsModule,
    SvgComponent,

  ],
  exports: [
    DeleteModalComponent,
    UploadThumbnailModalComponent
  ]
})
export class ModalModule {
}


