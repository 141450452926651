import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Role } from '@enums';
import { AuthService } from '@auth0/auth0-angular';
import { getActiveModule, getRole } from '@utils';
import { environment } from '@environment';

@Component({
  selector: 'app-redirect',
  template: `
    <div class="position-absolute redirect-loading top-50 start-50">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  `,
  styles: [
    `.redirect-loading {
      transform: translate(-50%, -50%);
    }`
  ]
})
export class RedirectComponent implements OnInit {

  redirect: string = '';

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.user$.subscribe(user => {
      if (!user) {
        this.authService.loginWithRedirect({appState: {redirect: this.router.url}});
      } else {
        this.navigateToContent(user);
      }
    });
  }

  navigateToContent(user: any): void {
    const role = getRole(user[environment.roleKey]);
    let url = ('/' + getActiveModule(role));

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (role === Role.USER && params['program']) {
        url += `/my-programs/${params['program']}`;
      }

      if (params['course']) {
        url += `/courses/${params['course']}`;
      }

      if (params['module']) {
        url += `#${params['module']}`;
      }
    });

    if (role === Role.USER) {
      window.location.href = url;
    } else {
      this.router.navigateByUrl(url);
    }
  }
}
