import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environment";
import {GatewayService} from "../gateway.service";
import {Response} from "@models";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventService extends GatewayService<Response<any>> {

  data: any;


  // @ts-ignore
  public triggerEvent = new BehaviorSubject<any>(this.data);

  constructor(http: HttpClient) {
    super(http);

    this.endpoint = `${environment.eventBaseUrl}/${environment.api}/${environment.version}`;
  }

  getEvents() {
    return this.read({endpoint: 'events'});
  }

  createEvent(courseId: number | string, moduleId: number | string, body: any) {

    return this.create({endpoint: `courses/${courseId}/modules/${moduleId}/events`, body})
  }

  editEvent(eventId: number | string, body: any) {
    return this.update({endpoint: `events/${eventId}`, body})
  }

  deleteEvent(eventId: number | string) {
    return this.delete({endpoint: `events/${eventId}`});
  }

  getSingleEvent(eventId: string | number) {
    return this.read({endpoint: `events/${eventId}`});
  }

  getAnnouncements() {
    return this.read({endpoint: '/announcements'});
  }

  createAnnouncement(body: any) {
    return this.create({endpoint: `announcements`, body})
  }

  editAnnouncement(announcementId: number | string, body: any) {
    return this.update({endpoint: `announcements/${announcementId}`, body})
  }

  getStudentAnnouncements() {
    return this.read({endpoint: '/learner/announcements'});
  }

  getSingleAnnouncement(announcementId: string | number) {
    return this.read({endpoint: `/announcements/${announcementId}`});
  }

  getSingleStudentAnnouncement(announcementId: string | number) {
    return this.read({endpoint: `/learner/announcements/${announcementId}`});
  }

  deleteAnnouncement(eventId: number | string) {
    return this.delete({endpoint: `announcements/${eventId}`});
  }

  setTriggerEvent(valueChange: boolean) {
    this.triggerEvent.next(valueChange);
  }

  getTriggerEvent() {
    return this.triggerEvent.asObservable();
  }


}
