import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: number | undefined): string {

    if (!value) {
      return '00:00:00';
    }

    let hours = Math.floor(value / 3600).toString().padStart(2, '0');
    let minutes = Math.floor((value % 3600) / 60).toString().padStart(2, '0');
    let seconds = (value % 60).toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  }
}
