import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsManagerService implements OnDestroy {
  private events = new Map<string, BehaviorSubject<any>>();
  private subscriptions = new Map<string, Subscription>();

  registerEvent(eventName: string, data: any = null): void {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, new BehaviorSubject<any>(data));
    }
  }

  getEvent(eventName: string) {
    if (this.events.has(eventName)) {
      return this.events.get(eventName);
    }

    throw new Error(`Event with key: ${eventName} not found`);
  }

  subscribe(eventName: string, callback: (data?: any) => void): Subscription {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, new BehaviorSubject<any>(null));
    }

    const subject = this.events.get(eventName);
    const subscription = subject?.subscribe(callback) as Subscription;

    this.subscriptions.set(eventName, subscription);

    return subscription;
  }

  emit(eventName: string, data?: any): void {
    const subject = this.events.get(eventName);

    if (subject) {
      subject.next(data);
    }
  }

  unsubscribe(eventName: string): void {
    const event = this.events.get(eventName);
    const subscription = this.subscriptions.get(eventName);


    if (event) {
      event.unsubscribe();
      this.events.delete(eventName);
    }

    if (subscription) {
      subscription.unsubscribe();
      this.subscriptions.delete(eventName);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
