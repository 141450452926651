<div class="modal-header bg-primary">
  <h5 class="modal-title text-white">{{ isImageUploaded ? 'Crop' : 'Choose' }} image</h5>
  <button class="btn btn-light" (click)="fileInput.click()" *ngIf="isImageUploaded || !thumbnail.isDefault else closeIconModal">
    {{ isImageUploaded || thumbnail.isDefault ? 'Change image' : 'Upload Another'}}
  </button>
  <ng-template #closeIconModal>
    <button class="btn-close btn-close-white"
            aria-label="Close"
            (click)="activeModal.close('close')">
    </button>
  </ng-template>
</div>

<div class="modal-body">
  <input type="file"
         id="file-upload"
         class="d-none"
         accept="image/x-png,image/jpeg,image/png"
         #fileInput
         (change)="fileChangeEvent($event)"/>

  <div class="p-5 text-center" *ngIf="!isImageUploaded && thumbnail.isDefault else showImage">
    <button class="btn btn-light" (click)="fileInput.click()">Upload Image</button>
  </div>

  <ng-template #showImage>
    <img
      [src]="thumbnail.imageUrls.img_original"
      class="m-auto text-center img-fluid"
      alt="Thumbnail"
      *ngIf="!isImageUploaded && thumbnail.imageUrls else imageCropper">
    <ng-template #imageCropper>
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="16 / 9"
        [resizeToWidth]="640"
        [cropperMinWidth]="600"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        [alignImage]="'center'"
        format="png"
        (imageCropped)="imageCropped($event)">
      </image-cropper>
    </ng-template>
  </ng-template>
</div>

<div class="modal-footer">
  <button class="btn btn-light me-2" (click)="activeModal.close('close')">
    Cancel
  </button>

  <button class="btn btn-primary" (click)="uploadThumbnail()" *ngIf="thumbnail.isDefault || isImageUploaded else deleteBtn">
    Upload
  </button>
  <ng-template #deleteBtn>
    <button class="btn btn-danger" (click)="deleteThumbnail()">
      Delete
    </button>
  </ng-template>
</div>
