import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from "@ng-select/ng-select";
import {SvgComponent} from "../svg/svg.component";

import {PaginationComponent} from './pagination.component';

@NgModule({
  declarations: [
    PaginationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbPaginationModule,
    NgSelectModule,
    SvgComponent
  ],
  exports: [
    PaginationComponent
  ]
})
export class PaginationModule {
}
