<div class="modal-header">
  <h5 class="modal-title">{{modalTitle}}</h5>
  <button (click)="activeModal.close('close')" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  <p *ngIf="!customMessage else showCustomMessage" class="mb-0">Are you sure you want to  {{message}}?</p>

  <ng-template #showCustomMessage>
    <p class="mb-0">{{ customMessage }}</p>
  </ng-template>
</div>

<div class="modal-footer">
  <button (click)="activeModal.close('close')" *ngIf="showCancelButton" class="btn btn-light me-2">
    {{cancelButtonText}}
  </button>
  <button (click)="activeModal.close(true)" [class]="'bg-' + confirmStatus" class="btn">
    {{confirmButtonText}}
  </button>
</div>
