<p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 24pt 0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">WE ENCOURAGE YOU TO READ THESE TERMS CAREFULLY AND TO&nbsp;</span><a href="mailto:enrollment@cydeo.com" style="text-decoration:none;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CONTACT US</span></a><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;IF YOU HAVE ANY QUESTIONS. BY USING OUR SERVICES OR REGISTERING FOR A COURSE, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OR ARE NOT ABLE TO ENTER INTO A BINDING AGREEMENT, THEN YOU MAY NOT REGISTER FOR A COURSE, OR USE OUR SERVICES.</span></p>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">A. Welcome to Cydeo!</span></h2>
<p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:24pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">This Agreement (&quot;Terms&quot;) sets out the terms and conditions that govern your use of Cydeo&apos;s products and services (including website services and course content), as well as your registration for any course or other program (&quot;Course&quot;) offered by Cydeo (Cybertek LLC).</span></p>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">By checking the box to acknowledge the terms and conditions, you confirm your understanding and agreement to adhere to all the terms outlined below. Failure to comply with these terms may lead to the termination of your course enrollment, and please be aware that no refunds will be granted.</span></p>
  </li>
</ol>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">B. Accessing Cydeo</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="2">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Acceptance: By clicking on the &quot;I Accept the Terms and Conditions for Students&quot; button when signing up for a Course, you agree to be bound by these &quot;Terms&quot;, which include, by reference, the Policies.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Accurate information</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: All information (including personal information) provided to us on registration, on creating an account, or while accessing Cydeo services and Courses, must be true, accurate, and complete. You are also responsible for updating us in the event of a change to your information (for example, your name or address).&nbsp;</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">License</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Subject to these Terms, and to the payment of any applicable fees, we grant you a limited, personal, non-exclusive, non-transferable, and revocable license to use our services and products, including all services associated with our Courses, and LMS through which Course content is accessed.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Suitability of Course:&nbsp;</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Before registering for any Course, you are responsible for establishing the relevance and suitability of the Course for your individual requirements, through consideration of the information supplied through our Websites, and asking questions, if necessary. Certain courses may require you to fulfill specific prerequisites before enrolling. Failing to meet these requirements could lead to challenges in comprehending the course material.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Commencement of Course</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: You will only receive confirmation of your registration for a Course, and be allowed access our Learning Management System (LMS), once you have made the required minimum payment for the Course. There may also be a delay of access until you are formally enrolled in our records, or until the Course commences as per your selected Course commencement date.</span></p>
  </li>
</ol>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">C. Course requirements</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="7">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Basic requirements</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: In order to register, receive certain support, and complete a Course, you should possess at least an intermediate level of proficiency in English. You will need a current valid email account and access to a computer and the internet. You should be familiar with using a computer and accessing the Internet.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Identification</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: During the Course presentation, you may be required to verify your identity with an official identity document, which reflects the name provided upon registration, for authentication and record purposes. Acceptable forms of identification include an identity document (national ID card), passport, government- or state-issued driver&apos;s license, state or province ID card, or birth certificate.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Education Records</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Cydeo reserves the right to deny your registration for that Course, remove you from that Course, suspend your activity on that Course, or withhold your credentials upon successful Course completion, on the basis of written reasons related to outstanding fees owing to Cydeo, misconduct or other lawful impediment.</span></p>
  </li>
</ol>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">D. Course delivery</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="10">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Language</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: English is the primary language of instruction and administration for all Courses, and all coursework and communication must be delivered in English.&nbsp;</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Communications</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: You may receive communication by telephone, email or text message from Cydeo representatives for Course administration and related purposes, including reminders of assignments and payments due by you, or other information related to your registration or these Terms.&nbsp;</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You agree and consent to receipt of such communications</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;(although you may opt out of any generic marketing communications that are unrelated to Course administration, at any time).</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Access to LMS</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Upon your successful enrollment for a Course, a profile will be set up for you in LMS. Please note that there may be some time delay between registration and enrollment.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Permitted use</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Only students who are registered for a Course may participate in that Course. You shall not divulge your account access to any other person, permit any other person to participate in the Course on your behalf, and/ or impersonate any other person in dealing with Cydeo or when accessing your program materials by using another student&apos;s account.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Prohibited use</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: You are prohibited from doing the following:</span></p>
    <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
      <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="2">
        <p dir="ltr" style="line-height:1.95;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Making available copies of the Course content (or any portion thereof) on a network server, web server, or another website, for use/examination by others;</span></p>
      </li>
      <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="2">
        <p dir="ltr" style="line-height:1.95;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Using, displaying, or otherwise making available the Course content (or any portion thereof), or any other materials, to third parties in an electronic or print format that enables it to be downloaded or distributed to any third party via a mobile device, or shared in any peer-to-peer or similar file sharing arrangement, or enabling the distribution of the Course content by any other means;</span></p>
      </li>
      <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="2">
        <p dir="ltr" style="line-height:1.95;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Sublicensing, reselling, renting, lending, assigning, ceding, donating, or otherwise transferring or distributing the Course content or your rights granted to you under these Terms</span></p>
      </li>
      <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="2">
        <p dir="ltr" style="line-height:1.95;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Reverse engineering, decompiling, or disassembling any software that is contained within Course content or in LMS; and</span></p>
      </li>
      <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="2">
        <p dir="ltr" style="line-height:1.95;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Removing any notice of copyright, trademark, or any other proprietary right from any place where it is displayed on or embedded in the Course content.</span></p>
      </li>
    </ul>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">External websites</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Certain Courses may require you to use external websites. Cydeo is not responsible for technical support for any external websites. If you have any queries relating to external websites, contact the support services of the relevant websites directly (although you may request assistance from our Tech Support Team). Cydeo will not be liable for any costs, claims, or damages that you may suffer as a result of your use/misuse of, or failure to access, any external website.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Videos and live sessions</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Videos and live sessions (when applicable) are provided in the majority of Course modules. These videos and live sessions act as tutorials and lectures for the topics covered in the specific modules and are compulsory unless otherwise stipulated. If you are using a slower internet connection, attempts to view these videos or live sessions may be difficult.</span></p>
  </li>
</ol>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">E. Student Conduct</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="17">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">General rules</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: All students are required to adhere to the Student Code of Conduct.&nbsp;</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Offensive and prohibited content and communications</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: All communications on our platform, including any content published by you, must remain appropriate at all times. Cydeo retains the right to monitor and investigate any communication which is alleged to be offensive or specifically prohibited. Cydeo retains the right to take any action that we deem appropriate to ensure that learning environment remains constructive.&nbsp;</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You agree to abide by the provisions of our Website Terms of Use in relation to acceptable use policies, especially in relation to offensive and prohibited conduct. In addition, you undertake not to use Cydeo services to promote any business or enterprise, unless permitted to do so by a representative of Cydeo.&nbsp;</span></p>
  </li>
</ol>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">F. Pricing, payment and delivery</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="19">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Course fees</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: When you register for a Course, you agree to pay the applicable Course fee and such other amounts as may be due by you arising from your participation in the Course. Course fees may vary based on your location and other factors. We reserve the right to change any Course fees at any time at our sole discretion.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Payment Options</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Cydeo, in its sole discretion, may offer select payment plan options, including financing by third parties, to facilitate your payment of select Course&rsquo;s fees. If a payment plan is offered for a Course, such plan(s) will be offered to you during your Course registration process. These plans may include but not limited to (i) pay your Course fees in full before the Course starts, (ii) be invoiced for your Course and required to pay the balance by a certain date.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Charges:&nbsp;</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CYDEO has an automated bank payment system (Stripe) which directly withdraws funds from the designated bank account if you choose that payment option. All credit card or debit card payments shall be subject to a 3% processing fee. Every returned electronic ACH check payment shall be subject to fees as allowed by applicable state law. In addition, access to all class materials will be removed until any such returned payment is paid.&nbsp;</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Consequences of non-payment</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: If you fail to make the minimum required payment for a Course by the due date for payment (as agreed during registration and recorded in the invoice), then we may, in our sole discretion, remove you from the Course or suspend your participation in the Course. If you are suspended from participation, you will not be permitted to access the materials until such time as we have received the outstanding payment that has fallen due. The party responsible for the payment of the invoice will be given a period of time within which to rectify non-payment and if payment is not made within the given additional period of time, then we may cancel your registration and remove you from the course and you will not be entitled to any refund.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Refund Policy</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Payments are nonrefundable and non-transferable.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Access expiration and subscriptions</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Your access to Cydeo platforms may be discontinued after a specified period following the course&apos;s end date. To maintain access, you can opt to subscribe and pay a subscription fee. The access cutoff time and subscription fee vary for each course. Please consult the information below for the policy of each course:</span></p>
  </li>
</ol>
<ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
  <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: 36pt;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SOC Training Course</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: During the course, two platforms will be utilized - LMS and the Security Operation Center (SOC). After the live course concludes, you will retain access to the LMS for up to one year, while access to the Security Operation Center will be discontinued. If you wish to maintain access to the SOC, you can do so by subscribing to a monthly plan with a fee of $99. To learn more about the subscription process, please get in touch with our accounting team at accounting@cydeo.com.</span></p>
  </li>
</ul>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">G. Termination of participation in a Course</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="25">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Modifying and terminating services</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: We are constantly changing and improving our services and Courses. We may add or remove functions, features, or requirements, and we may suspend or stop our service altogether, giving enrolled students reasonable prior notice of any material changes. You may also discontinue your use of our services and Courses at any time.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Breach</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: If you breach these Terms and fail to remedy the breach within five (5) days after receiving a written notice, we may terminate your participation in the Course for which you are registered, and you will not be entitled to a refund of any portion of the Course fee. We shall furthermore be entitled to immediately suspend your access to our platforms in the event of a material breach of these Terms, as determined in our sole discretion, pending an investigation into the relevant conduct.</span></p>
  </li>
</ol>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">H. Complaints and dispute resolution</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="27">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">General complaints policy</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Whether it is positive or negative, Cydeo encourages feedback from students. Where this feedback is a complaint about our Course, product or service offering, or our conduct, we are committed to addressing the complaint in a timely and appropriate manner.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Lodging a complaint: Should you wish to lodge a complaint, you are required to submit an official email to enrollment@cydeo.com</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">. We will strive to ensure that anyone giving feedback is treated with the utmost courtesy and respect, and in return, we expect that anyone giving feedback or making a complaint will do so in a fair and appropriate manner. Where we determine that a complaint is abusive, unreasonable, or a student is unreasonably pursuing a complaint that has previously been investigated, we reserve our rights in relation to our response.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Review and investigation</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: Once a complaint has been lodged, we will investigate and attempt to address the matter in question. At this stage, you may be required to provide supporting documentation or other evidence that may be relevant.</span></p>
  </li>
</ol>
<h2 dir="ltr" style="line-height:2.1176470588235294;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"><span style="font-size: 26px; font-family: Arial, sans-serif; color: rgb(8, 33, 53); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">I. General</span></h2>
<ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;" start="30">
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cydeo&rsquo;s details</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: These terms are a contract between you and Cydeo. Cydeo is a Virginia limited liability company with its address at 7925 Jones Branch Dr., Suite 3300, McLean VA 22102.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Entire agreement</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: These Terms, together with the Honor Code and Policies, shall constitute the entire agreement between you and Cydeo concerning your registration for and participation in a Course.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Revision of Terms</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: We reserve the right to revise these Terms (including Policies) at our sole discretion from time to time. We will take reasonable steps to notify you of such changes if you are registered for a Course at the time when such changes come into effect.</span></p>
  </li>
  <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;" aria-level="1">
    <p dir="ltr" style="line-height:1.95;background-color:#ffffff;margin-top:0pt;margin-bottom:36pt;"><span style="font-size:12pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">No indulgence/waiver</span><span style="font-size:12pt;font-family:Arial,sans-serif;color:#666666;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">: If Cydeo chooses not to enforce any part of these Terms, this does not mean that it cannot do so at a later time. No waiver of any term shall be deemed a further or continuing waiver of such term.</span></p>
  </li>
</ol>
<p dir="ltr" style="line-height:1.38;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;"><span style="font-size:10.5pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Date of last amendment:</span><span style="font-size:10.5pt;font-family:Arial,sans-serif;color:#212529;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;</span><span style="font-size:10.5pt;font-family:Arial,sans-serif;color:#082135;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">November 1, 2023</span></p>
<p><br></p>
<p><br></p>
<p><br></p>
