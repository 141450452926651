import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environment';
import {isEmpty} from '@utils';
import {Request, RequestOptions} from '@models';

@Injectable({
  providedIn: 'root'
})
export class GatewayService<T = any> {

  private $endpoint: string = environment.baseUrl;

  constructor(private http$: HttpClient) {
  }

  get http(): HttpClient {
    return this.http$;
  }

  get endpoint() {
    return this.$endpoint;
  }

  set endpoint(endpoint: string) {
    if (isEmpty(endpoint)) {
      throw new Error('Endpoint is required.');
    }

    this.$endpoint = `${this.endpoint}/${endpoint}`;
  }

  create(request?: Request): Observable<T> {
    return this.request<T>('POST', request);
  }


  read(request?: Request): Observable<T> {
    return this.request<T>('GET', request);
  }

  update(request?: Request): Observable<T> {
    return this.request<T>('PUT', request);
  }

  delete(request?: Request): Observable<T> {
    return this.request<T>('DELETE', request);
  }

  request<T>(method: 'POST' | 'GET' | 'PUT' | 'DELETE' = 'GET', request: Request = {}): Observable<T> {
    const options: RequestOptions = {
      observe: 'body',
      responseType: 'json',
      ...(request.options || {})
    };

    if (request.body) {
      options.body = request.body;
    }

    if (request.params) {
      options.params = request.params;
    }

    let endpoint = request.newEndpoint ?
      `${environment.baseUrl}/${request.newEndpoint}` :
      this.endpoint + (isEmpty(request.endpoint) ? '' : '/' + request.endpoint);

    if (request.appendQueryParams) {
      endpoint += '?' + request.appendQueryParams;
    }

    return this.http.request(method, endpoint, options);
  }
}
