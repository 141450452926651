<div  id="staticBackdrop" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">

  <div [class]="'video-question-modal'">
    <div class="quiz-modal-container">
      <div class="quiz-modal-header">
      </div>
      <div class="quiz-modal-body">
        <p> {{questionText}}</p>
      </div>
      <hr>
    <form *ngIf="formCreated" [formGroup]="quizForm" (submit)="submitAnswer()">
      <div class="video-quiz-questions" *ngIf="questions?.length > 0">
          <div class="radio-holder"  *ngIf="questionType !== 'MULTIPLE_ANSWER'">
            <div *ngFor="let question of questions">
              <input type="radio" [formControlName]="'radioAnswer'" [id]="question?.videoQuestionAnswerId" [disabled]="submitted" [checked]="false" [value]="question?.videoQuestionAnswerId"/>
              <label  [id]="question?.videoQuestionAnswerId" > {{question.videoQuestionAnswerText}}</label>
              <app-svg *ngIf="!question.isCorrect && submitted && result === false" [name]="'close'" width="20" height="20" class="question-svg line-height-0"  [ngClass]="'text-danger'"></app-svg>
              <app-svg  *ngIf="question.isCorrect && submitted" width="20" height="20" [name]="'checkmark'" class="question-svg text-success"></app-svg>
            </div>
          </div>

          <div class="radio-holder"  *ngIf="questionType === 'MULTIPLE_ANSWER'">
            <div *ngFor="let question of questions">
              <input type="checkbox"  [formControlName]="question?.videoQuestionAnswerId"  [id]="question?.videoQuestionAnswerId" name="question" [disabled]="submitted" />
              <label  [id]="question?.videoQuestionAnswerId" > {{question.videoQuestionAnswerText}}</label>
              <app-svg *ngIf="!question.isCorrect && submitted && result === false" [name]="'close'" width="20" height="20" class="question-svg line-height-0"  [ngClass]="'text-danger'"></app-svg>
              <app-svg  *ngIf="question.isCorrect && submitted"  [name]="'checkmark'" class="question-svg text-success" width="20" height="20"></app-svg>

            </div>
          </div>
      </div>
      <div class="quiz-modal-footer">
        <div class="modal-action-buttons">
          <button *ngIf="!submitted" [disabled]="!quizForm.dirty" class="btn btn-primary" type="submit">
            Submit
          </button>
          <button *ngIf="submitted" class="btn btn-primary" (click)="submitModal()">
            Continue
          </button>
        </div>
      </div>
    </form>
    </div>
  </div>
</div>
