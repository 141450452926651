import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {
  @Input() message!: string;
  @Input() removeLabel: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              private platformLocation: PlatformLocation) {
  }

  ngOnInit(): void {
    this.platformLocation.onPopState(() => this.activeModal.dismiss());
  }
}
