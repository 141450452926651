import { Answer } from '@models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from './validators';

export const newAnswer = (answer: Answer): FormGroup => {
  const args: any = {};

  Object.keys(answer).forEach(key => {
    if (key === 'answer' || key === 'videoQuestionAnswerText') {
      args[key] = new FormControl(answer[key], [Validators.required, CustomValidators.empty])
    } else {
      args[key] = new FormControl(answer[key])
    }
  });

  return new FormGroup(args);
}
