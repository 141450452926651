import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";

import {BreadcrumbComponent} from './breadcrumb.component';
import { PipesModule } from '@pipes';


@NgModule({
  declarations: [
    BreadcrumbComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        PipesModule
    ],
  exports: [
    BreadcrumbComponent
  ]
})
export class BreadcrumbModule {
}
