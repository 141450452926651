export const findDuplicateStringsInObject = (obj: any, prop: string): boolean => {
  let flattened = obj.flatMap((value: any) => {
    if (typeof value[prop] === 'string') {
      return value[prop];
    }
    return [];
  });

  let duplicates = flattened.filter((value: any, index: number) => flattened.indexOf(value) !== index);
  return duplicates.length > 0;
}
