import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@environment";
import {GatewayService} from "@services";
import {Module, Response} from "@models";

@Injectable({
  providedIn: 'root'
})
export class ModuleService extends GatewayService<Response<Module>> {

  constructor(http: HttpClient) {
    super(http);

    this.endpoint = `${environment.programBaseUrl}/${environment.api}/${environment.version}/modules`;
  }

  getSingleModule(moduleId: any, params: any) {
    return this.read({endpoint: `${moduleId}/`, params})
  }

  getModuleContents(moduleId: any, params: any) {
    return this.read({endpoint: `${moduleId}/`, params})
  }
}
