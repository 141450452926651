<nav aria-label="breadcrumb" [class]="classes">
  <ol class="breadcrumb">
    <ng-container *ngFor="let b of breadcrumb; let i = index">
      <li class="breadcrumb-item"
          [ngClass]="{
          'active': i === breadcrumb.length - 1,
          'empty': !b.url
           }"
          *ngIf="!b.hide">
        <a [routerLink]="b.url | typeof" [queryParams]="b.queryParams">
          {{ b.label }}
        </a>
      </li>
    </ng-container>
  </ol>
</nav>
