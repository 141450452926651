import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environment';
import * as analytics from './analytics';

if (environment.production) {
  enableProdMode();
}

//analytics.trackErrors();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
