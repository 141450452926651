import {Module, Role} from "@enums";

export const getRole = (roles: string[]): string => {
  if (roles.indexOf(Role.SUPER_ADMIN) > -1) {
    return Role.SUPER_ADMIN;
  } else if (roles.indexOf(Role.ADMIN) > -1) {
    return Role.ADMIN;
  } else if (roles.indexOf(Role.MODERATOR) > -1) {
    return Role.MODERATOR;
  } else if (roles.indexOf(Role.INSTRUCTOR) > -1) {
    return Role.INSTRUCTOR;
  } else if (roles.indexOf(Role.MENTOR) > -1) {
    return Role.MENTOR;
  }

  return Role.USER;
}

export const getActiveModule = (role: string): string => {
  return role === Role.USER ? Module.MEMBER : Module.ACCOUNT;
}
