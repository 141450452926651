export class DropdownFilter {
  id: number | string;
  title: string;
  operator: 'eq';
  selected: DropdownFilterItem;
  items: DropdownFilterItem[] = [];

  constructor(id: string,
              title: string,
              operator: 'eq',
              items: DropdownFilterItem[],
              selected?: DropdownFilterItem) {
    if (items.length === 0) {
      throw new Error('Please provide dropdown items');
    }

    this.id = id;
    this.title = title;
    this.operator = operator;
    this.items = items;

    this.selected = selected || items[0];
  }

  remove(id: string) {
    this.items = this.items.filter(item => item.id !== id);
  }
}

export interface DropdownFilterItem {
  id: string;
  label: string;
}
