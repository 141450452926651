import { Component } from '@angular/core';
import {NgbDateStruct, NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "@auth0/auth0-angular";
import {DomSanitizer} from "@angular/platform-browser";
import {continents, countries, getCountryDataList, languages} from 'countries-list'
import {AppModule} from "../../../app.module";
import {LicenceAgreementComponent} from "../licence-agreement/licence-agreement.component";
import {GatewayService} from "../../../core/services/gateway/gateway.service";
import {ApiEndpoints, urlBuilder} from "@constants";
import {environment} from "../../../../environments/environment.dev";
import {HttpClient} from "@angular/common/http";
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  imports: [
    NgbInputDatepicker,
    FormsModule,
    NgIf,
    NgClass,
    NgForOf,
    ReactiveFormsModule,
    LicenceAgreementComponent,
  ],
  standalone: true
})
export class ShopComponent {
  model: any = '';
  step: number = 1;
  a: any = '';
  termsConditions = false;
  fullLegalName = '';
  prerequisites = false;
  stripeReady= false;
  form: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    birthdate: new FormControl('', [Validators.required]),
    address: this.formBuilder.group({
      street: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
    }),
    //referer: new FormControl(''),
  });
  countries:any = [];
  constructor(private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private gatewayService: GatewayService,
              private datePipe: DatePipe,
              public authService: AuthService,
              private sanitizer:DomSanitizer,
              private http: HttpClient
  ) {
    if(this.step === 5  && this.getCookie('usdta')){
      console.log('Y c')
      this.sendFormData(this.getCookie('usdta'));
    }else {
      console.log('N c');
    }
    this.a = sanitizer.bypassSecurityTrustHtml("<stripe-pricing-table pricing-table-id=\"prctbl_1O7jCAFVWl0DTIsQiwclI3OH\"\n" +
        "publishable-key=\"pk_test_51O4sGtFVWl0DTIsQ5JMGl2wctY0eXDxUKdUlUI8NtR3GLLzw9xwa6JCtFj5eKVlQVc0P1qubLQQicfkyx3onRyok00ZHKau6PV\">\n" +
        "</stripe-pricing-table>");
    let payment = this.route.snapshot.queryParams['payment'];
    if(payment === 'success'){
      this.step = 5;
      let cookieData = this.getCookie('usdta');
      if(cookieData){
        this.sendFormData(cookieData);
      }
      console.log(this.getCookie('usdta'));
      this.eraseCookie('usdta');
    }
    this.countries = getCountryDataList();
  }


  ngOnInit(){
  }
  eraseCookie(name:string) {
    document.cookie = name+'=; Max-Age=-99999999;';
  }

  sendFormData(body:any){
    if(body.customer?.birthdate){
      let date: any = body.customer.birthdate;
      const formattedDate = `${date.month}-${date.day}-${date.year}`;
      let newDate = this.datePipe.transform(new Date(formattedDate), 'dd-MM-yyyy')
      body.customer.birthdate = newDate;
      body.customer.gender = (body.customer.gender.toUpperCase())

      this.http.post<any>(environment.shopUrl + '/api/v1/purchases', body).subscribe((data:any) => {
      });
    }

  }

  submitForm(){

    //this.sendFormData();
    if(!this.form.valid){
      console.log('values', this.form.getRawValue());
      return;
    }
    this.step = this.step + 1;
  }
  prevStep(){
    if(this.step > 1){
      this.step = this.step - 1;
    }
  }
  nextStep(step?: number){
    if(step && step === 3){
      if(!(this.fullLegalName.length > 3) || !this.termsConditions || !this.prerequisites){
        return;
      } else {
        console.log('save data');
        this.saveData();
      }
    }
    if(this.step < 4){
      this.step = this.step + 1;
    }
  }
  setCookie(cname:any, cvalue:any) {
    const d = new Date();
    d.setTime(d.getTime() + (330000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + JSON.stringify(cvalue) + ';' + expires + ';path=/';
  }

  getCookie(cname:any) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (const i of ca){
      let c = i;
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const cookie = c.substring(name.length, c.length);
        return JSON.parse(cookie);
      }
    }
    return null;
  }
  saveData(){
    let form = this.form;
    let product = {
      "productProgramId": 116,
      "productProgramName": "ABC",
      "productProgramCode": "SOC-US001"
    };
    let legalAgreement= {
      programPrerequisites: this.prerequisites,
       privacyPolicy: this.termsConditions,
       legalFullName: this.fullLegalName
    }
    let body:any = {customer: form.getRawValue(), product: product, legalAgreement: legalAgreement};
    this.setCookie('usdta', body);
    //this.sendFormData(body)
  }

}
