import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() message!: string;
  @Input() modalTitle!: string;
  @Input() cancelButtonText!: string;
  @Input() confirmButtonText!: string;
  @Input() customMessage?: string;
  @Input() confirmStatus: 'primary' | 'success' | 'info' | 'danger' = 'danger';
  @Input() showCancelButton: boolean = true;

  constructor(public activeModal: NgbActiveModal,
              private platformLocation: PlatformLocation) {
  }

  ngOnInit(): void {
    this.platformLocation.onPopState(() => this.activeModal.dismiss());
  }


}
