import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {isEmpty} from "@utils";
import {DropdownFilter, Pagination} from "@models";

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {

  @Input('search') searchValue: string = '';
  @Input() searchPlaceholder: string = '';
  @Input() hideSearch = false;
  @Input() disableSearch = false;
  @Input() pagination?: Pagination;
  @Input() dropdownFilters: DropdownFilter[] = [];
  toggleDropdownFilters = false;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() dropdownFiltersChange: EventEmitter<DropdownFilter[]> = new EventEmitter<DropdownFilter[]>();

  constructor() {
  }

  ngOnInit(): void {
  }

  search(isClickTrigger = false): void {
    if (isClickTrigger && isEmpty(this.searchValue)) {
      return;
    }

    this.searchValue = this.searchValue.trim();
    this.setPaginationInitPage();
    this.searchChange.emit(this.searchValue);
  }

  clearSearch(): void {
    this.setPaginationInitPage();
    this.searchChange.emit(this.searchValue = '');
  }

  setPaginationInitPage(): void {
    if (this.pagination) {
      this.pagination.page = 1;
    }
  }

  emitFilters(): void {
    if (this.pagination) {
      this.pagination.page = 1;
    }

    this.dropdownFiltersChange.emit(this.dropdownFilters);
  }

  clearFilter(dropdown: DropdownFilter): void {
    dropdown.selected = {
      id: '',
      label: 'All'
    };

    this.emitFilters();
  }
}
