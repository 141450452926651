import {Component, Input, OnInit} from '@angular/core';
import {Breadcrumb} from "@models";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() classes: string | string[] = ['mb-4'];
  @Input() breadcrumb: Breadcrumb[] = [];
  @Input() showHome = true;

  constructor() {
  }

  ngOnInit(): void {

    if (!this.showHome) {
      return;
    }

    this.breadcrumb.unshift({
      label: 'Home',
      url: ['/']
    });
  }
}
