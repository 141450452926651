<div class="table-responsive">
  <table [class]="tableClass" class="table table-hover mb-0">
    <ng-template [ngIf]="showHeader">
      <thead>
      <tr>
        <!-- Reorder -->
        <th *ngIf="reorder" class="table-cell table-column"></th>

        <th (click)="setSort(column)"
            *ngFor="let column of columns; let i = index"
            class="table-cell table-column">
          <div class="d-flex align-items-center">
            <span>{{ column.label }}</span>

            <!-- Sorting & Arrows -->
            <div *ngIf="column.sortable" class="table-sort d-flex flex-column align-items-center ms-2">
              <span [ngClass]="{'active': column.sortState === 1 }"
                    [ngStyle]="{'height.px': sortIconSize }"
                    class="table-sort-icon"
                    role="button">
                <app-svg [height]="sortIconSize"
                         [width]="sortIconSize"
                         name="arrow-up">
                </app-svg>
              </span>
              <span [ngClass]="{'active': column.sortState === 2 }"
                    [ngStyle]="{'height.px': sortIconSize }"
                    class="table-sort-icon"
                    role="button">
                <app-svg [height]="sortIconSize"
                         [width]="sortIconSize"
                         name="arrow-down">
                </app-svg>
              </span>
            </div>
          </div>
        </th>

        <ng-template [ifRoles]="[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MODERATOR, ROLE.INSTRUCTOR]">
          <th *ngIf="actions.length > 0" class="table-column table-cell table-actions">
          </th>
        </ng-template>
      </tr>
      </thead>
    </ng-template>

    <tbody [(dragulaModel)]="dataSource" [dragula]="reorder ? 'dataSource' : ''">
    <ng-container *ngIf="columns.length && (dataSource && dataSource.length); else emptyTable">
      <tr *ngFor="let data of dataSource; trackBy: trackByFn.bind(this, trackKey)"
          [ngClass]="{'bg-gray pe-none': disableRow | typeof:data }">

        <!-- Reorder -->
        <td *ngIf="reorder" class="table-cell">
          <div class="table-data">
            <!-- Reorder -->
            <span class="me-2 drag-row drag-handle"
                  role="button">
                    <app-svg name="drag-reorder"></app-svg>
                  </span>
          </div>
        </td>

        <td *ngFor="let column of columns"
            class="table-cell">
          <ng-container *ngIf="column.component else notComponent">
            <ng-container
              *ngComponentOutlet="column.component; injector:createInjector | typeof:{ row: data, column, options: column.options }">
            </ng-container>
          </ng-container>

          <ng-template #notComponent>
            <div (click)="onClickColumn(column, data)"
                 [ngClass]="{'cursor-pointer': column.clickable ? (column.clickable | typeof:data) : !!column.handler }"
                 class="table-data text-break">

              <ng-container *ngIf="column.url else plainText">
                <a [routerLink]="column.url | typeof:data" class="text-body">
                  {{ column.transform ? (column.transform | typeof:data) : (getData | typeof:data:column) }}
                </a>
              </ng-container>
              <ng-template #plainText>
                {{ column.transform ? (column.transform | typeof:data) : (getData | typeof:data:column) }}
              </ng-template>
            </div>
          </ng-template>
        </td>

        <ng-template [ifRoles]="[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.MODERATOR, ROLE.INSTRUCTOR]">
          <td *ngIf="actions.length > 0" class="table-cell table-actions text-center">

            <!-- Single action -->
            <ng-container *ngIf="actions.length === 1; else showActions">
              <button (click)="actions[0].handler($event, data, actions[0])"
                      *ngIf="actions[0].condition ? (actions[0].condition | typeof:data) : true"
                      [ngbTooltip]="(render | typeof:actions[0].label:data) || ''"
                      class="btn btn-light rounded-3"
                      type="button">

                <!-- Show only icon -->
                <ng-container *ngIf="actions[0].icon else showText">
                  <app-svg *ngIf="actions[0].icon" [name]="render | typeof:actions[0].icon:data"></app-svg>
                </ng-container>

                <!-- Show only text (If icon missing) -->
                <ng-template #showText>
                  {{ render | typeof:actions[0].label:data }}
                </ng-template>
              </button>
            </ng-container>

            <!-- List of actions -->
            <ng-template #showActions>
              <div container="body" ngbDropdown>
                <button class="btn btn-light rounded-3" ngbDropdownToggle>
                  <app-svg name="more"></app-svg>
                </button>

                <div aria-labelledby="actions" ngbDropdownMenu>
                  <ng-container *ngFor="let action of actions">
                    <button (click)="action.handler($event, data, action)"
                            *ngIf="action.condition ? (action.condition | typeof:data) : true"
                            class="d-flex align-items-center justify-content-between w-100"
                            ngbDropdownItem
                            type="button">
                      {{ render | typeof:action.label:data }}
                      <app-svg *ngIf="action.icon" [name]="render | typeof:action.icon:data" class="ms-3"></app-svg>
                    </button>
                  </ng-container>
                </div>
              </div>
            </ng-template>
          </td>
        </ng-template>
      </tr>
    </ng-container>

    <!-- Empty table -->
    <ng-template #emptyTable>
      <tr class="text-center">
        <td [colSpan]="columns.length + (actions.length ? 1 : 0)" class="p-3">
          {{ emptyMessage }}
        </td>
      </tr>
    </ng-template>
    </tbody>
  </table>
</div>
