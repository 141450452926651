import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NgbDropdownModule, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { SvgComponent } from "../svg/svg.component";
import { IfRolesDirective } from "../../directives/ifRoles/if-roles.directive";
import { PipesModule } from '@pipes';

import { TableComponent } from './table.component';
import { HeaderComponent } from './header/header.component';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  declarations: [
    TableComponent,
    HeaderComponent,
  ],
    imports: [
        CommonModule,
        RouterLink,
        NgbDropdownModule,
        SvgComponent,
        IfRolesDirective,
        NgbTooltip,
        PipesModule,
        DragulaModule,
    ],
  exports: [
    TableComponent
  ]
})
export class TableModule {
}
