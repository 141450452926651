import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '@services';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  host: {
    'class': 'toast-container position-fixed pb-3 bottom-0 start-50 translate-middle-x text-break'
  }
})
export class ToastComponent implements OnInit {

  constructor(public toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  isTemplate(toast: any): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
