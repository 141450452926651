import { Component, OnInit } from '@angular/core';
import { General } from "@models";
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@environment';
import { getRole } from '@utils';
import { Module, Role } from '@enums';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  logo = {
    src: General.logo2.png,
    title: General.title
  }
  notFoundLogo = General.notFound;
  loading: boolean = true;

  constructor(private router: Router,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    const url = this.router.url;

    console.log('Account: 404 Page!');
    console.log('Account: Current url', url);

    this.authService.user$.subscribe(user => {
      if (!user) {
        this.authService.loginWithRedirect({appState: {redirect: url}});
      } else {
        if (!environment.production) {
          if (getRole(user[environment.roleKey]) === Role.USER && url.indexOf('/member') > -1) {
            window.location.href = Module.ACCOUNT + url;
          }
        }

        this.loading = false;
      }
    });
  }
}
