export const ImageDir = 'assets/images/';

export const General = {
  title: 'Cydeo',
  logo: {
    png: ImageDir.concat('logo.png'),
    svg: ImageDir.concat('logo.svg'),
  },
  logo2: {
    png: ImageDir.concat('logo2.png'),
    svg: ImageDir.concat('logo2.svg')
  },
  notFound: ImageDir.concat('not-found.svg'),
  logoResponsive: ImageDir.concat('logo-responsive.svg'),
  loginBg: ImageDir.concat('login-background.jpg'),
  pageTitle(title: string): string {
    title = title.charAt(0).toUpperCase() + title.slice(1);

    return title.concat(' - ').concat(this.title);
  }
};
