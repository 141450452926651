import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Action, Cell, Column, Sort } from "@models";
import { Role, SortState } from "@enums";
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() columns: Column[] = [];
  @Input() dataSource: any[] = [];
  @Input() showHeader = true;
  @Input() emptyMessage = 'No data to provide';
  @Input() sort: Sort = {};
  @Input() actions: Action[] = [];
  @Input() tableClass: string | string[] = [];
  @Input() disableRow?: ((model?: any) => boolean) | boolean = false;
  @Input() trackKey: string = '';
  @Input() reorder: boolean = false;

  readonly ROLE = Role;
  sortIconSize: number = 14;

  subscription?: Subscription;

  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() reorderChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dragulaService: DragulaService) {
  }

  ngOnInit(): void {
    if (this.reorder) {
      this.initReorder();
    }
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy('dataSource');

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initReorder(): void {
    this.dragulaService.createGroup('dataSource', {
      moves: (el, container, handle) => {

        if (!this.reorder) {
          return false;
        }

        if (handle instanceof SVGElement) {
          return false;
        }

        return !!(handle && handle.className && handle.className.indexOf('drag-row') > -1);
      }
    });

    this.subscription = this.dragulaService.dropModel('dataSource')
      .subscribe((args) => {
        this.reorderChange.emit(args);
      });
  }

  getData(data: any, column: Column) {
    const splitField = column.field.split('.');

    if (splitField.length > 0) {
      return splitField.reduce((o: any, p: string) => o ? o[p] : null, data)
    }

    return data[column.field];
  }

  createInjector(data: { row: any; column: Column }): Injector {
    return Injector.create({
      providers: [
        {
          provide: Cell,
          useValue: data
        }
      ]
    })
  }

  setSort(column: Column): void {
    if (!column.sortable) {
      return;
    }

    const {sort, sortChange} = this;
    let nextSort = column.sortState || 0;

    const sortField = (column.sortField || column.field).split('.');

    if (!(sortField && sortField.length)) {
      throw new Error('Sort field is required');
    }

    column.sortField = sortField[sortField.length - 1];
    column.sortState = ++nextSort > 2 ? 0 : nextSort;

    if (sort[column.sortField] && SortState[column.sortState] === 'none') {
      delete sort[column.sortField];
    } else {
      sort[column.sortField] = SortState[column.sortState];
    }

    sortChange.emit(sort);
  }

  render(prop: any, data: any): string {
    return typeof prop === 'string' ? prop : prop(data);
  }

  trackByFn(trackKey: string, index: number, item: any) {
    return trackKey ? item[trackKey] : index;
  }

  onClickColumn(column: Column, data: any) {
    if (column.clickable !== undefined) {
      const columnClickable = typeof column.clickable === 'function' ? column.clickable(data) : column.clickable;

      if (!columnClickable) {
        return;
      }
    }

    if (!column.handler) {
      return;
    }

    return column.handler(data);
  }
}
