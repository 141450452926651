import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, throwError } from 'rxjs';
import { Request, RequestOptions, Response } from '../../../shared/models/rest.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  constructor(private httpClient: HttpClient) {
  }

  get http() {
    return this.httpClient;
  }

  create<T = any>(request: Request): Observable<Response<T>> {
    return this.request<T>('POST', request);
  }

  read<T = any>(request: Request): Observable<Response<T>> {
    return this.request<T>('GET', request);
  }

  update<T = any>(request: Request): Observable<Response<T>> {
    return this.request<T>('PUT', request);
  }

  delete<T = any>(request: Request): Observable<Response<T>> {
    return this.request<T>('DELETE', request);
  }

  request<T = any>(method: 'POST' | 'GET' | 'PUT' | 'DELETE', request: Request): Observable<Response<T>> | any {
    const options: RequestOptions = {
      observe: 'body',
      responseType: 'json',
      ...(request.options || {})
    };

    if (request.target) {
      request.target.disabled = true;
    }

    if (!options.body && request.body) {
      options.body = request.body;
    }

    if (!options.params && request.params) {
      options.params = request.params;
    }

    return this.httpClient.request(method, request.endpoint, options)
      .pipe(
        map((response) => {

          if (request.target) {
            request.target.disabled = false;
          }

          return response;
        }),
        catchError((error) => {

          if (request.target) {
            request.target.disabled = false;
          }

          return throwError(error);
        })
      );
  }
}
