import {Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {base64ToFile, ImageCroppedEvent} from "ngx-image-cropper";
import {Subscription} from 'rxjs';
import {ToastService} from "@services";
import {GatewayService} from '../../../../core/services/gateway/gateway.service';
import {Thumbnail} from "@models";
import {Request} from '../../../models/rest.model';
import {EventsManagerService} from '../../../../core/services/events-manager/events-manager.service';
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-upload-thumbnail-modal',
  templateUrl: './upload-thumbnail-modal.component.html',
  styleUrls: ['./upload-thumbnail-modal.component.scss']
})
export class UploadThumbnailModalComponent implements OnDestroy {
  @ViewChild('fileInput', {static: true}) fileInput!: ElementRef;
  @Input() thumbnail!: Thumbnail;
  @Input() endpoint!: string;
  imageLoad: any = {
    size: '',
    height: '',
    width: ''
  };
  imageChangedEvent: any = '';
  croppedImage: any = '';
  isImageUploaded = false;
  subscription: Subscription = new Subscription();

  constructor(public activeModal: NgbActiveModal,
              private platformLocation: PlatformLocation,
              private toastService: ToastService,
              private renderer: Renderer2,
              private gatewayService: GatewayService,
              private eventsManagerService: EventsManagerService) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.platformLocation.onPopState(() => this.activeModal.dismiss());
  }

  fileChangeEvent(event: any): void {
    this.isImageUploaded = true;

    const fileInput = this.fileInput.nativeElement;

    if (!fileInput.files || !fileInput.files.length) {
      return;
    }

    const file = fileInput.files[0];
    const reader = new FileReader();
    let imageLoad = this.imageLoad;

    reader.readAsDataURL(file);
    reader.onload = evt => {
      imageLoad = new Image();
      imageLoad.onload = () => {
        if (imageLoad.width < 640) {
          this.isImageUploaded = false;
          this.renderer.setValue(this.fileInput, '');
          this.toastService.show('The uploaded image is too small. Minimum image width is 640px', {classes: 'bg-danger'});
        } else {
          this.isImageUploaded = true;
          this.imageChangedEvent = event;
        }
      };
      imageLoad.src = evt?.target?.result;
    };

    reader.onerror = evt => {
      console.error(evt);
    };
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64 as string;
  }

  generateString() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  uploadThumbnail(): void {
    const imageFile = new File(
      [base64ToFile(this.croppedImage)],
      `${Date.now()}_${this.generateString()}.png`,
      {type: 'image/png'});

    const formData = new FormData();
    formData.append('thumbnail', imageFile);

    const request: Request = {
      endpoint: this.endpoint,
      body: formData
    };

    this.subscription.add(this.gatewayService.create(request).subscribe(() => {
      this.eventsManagerService.emit('fetchData', true);
      this.toastService.show(`Thumbnail uploaded successfully`, {classes: 'bg-success'});
      this.activeModal.close(true);
    }));
  }

  deleteThumbnail(): void {
    const request: Request = {
      endpoint: this.endpoint,
    };

    this.subscription.add(this.gatewayService.delete(request).subscribe(() => {
      this.toastService.show(`Thumbnail deleted successfully`, {classes: 'bg-success'});
      this.eventsManagerService.emit('fetchData', true);
      this.activeModal.close(true);
    }));
  }
}
