import {Injectable} from '@angular/core';
import {GatewayService} from "@services";
import {Program, Response} from "@models";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environment";
import {BehaviorSubject} from "rxjs";
import {appendQueryParams} from "@utils";

@Injectable({
  providedIn: 'root'
})
export class ProgramService extends GatewayService<Response<any>> {
  private programBehavior: BehaviorSubject<Program | null> = new BehaviorSubject<Program | null>(null);
  program$ = this.programBehavior.asObservable();

  constructor(http: HttpClient) {
    super(http);

    this.endpoint = `${environment.programBaseUrl}/${environment.api}/${environment.version}/programs`;
  }

  set program(program: Program | null) {
    this.programBehavior.next(program);
  }

  search(params: { [key: string]: number | string }) {
    const newEndpoint = `${environment.programBaseUrl}/${environment.api}/${environment.version}/search`;

    return this.read({newEndpoint, params});
  }

  getAllPrograms<T>(includes?: any[], headers?: any) {

    const endpoint = appendQueryParams(this.endpoint, includes);

    return this.http.get<T[]>(endpoint, headers);
  }

  getInstructors(programId: string) {
    return this.read({endpoint: `${programId}/instructors`});
  }

  getProgramCourses(programId: string, params: any) {
    return this.read({endpoint: `${programId}/courses`, params});
  }

  getCurrentProgram(programId: string | number) {

    return this.read({endpoint: `${programId}`});
  }
}
