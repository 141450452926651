import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <div class="app shop-ui">
      <router-outlet></router-outlet>
    </div>
  `
})
export class AppComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    if (localStorage.getItem('viewAsStudent')) {
      localStorage.removeItem('viewAsStudent');
    }
  }
}
