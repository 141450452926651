import { Component } from '@angular/core';
import {General} from "@models";
import {AuthService} from "@auth0/auth0-angular";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent {
  logo = {
    src: General.logo2.png,
  };

  a :any= '';
  constructor(public authService: AuthService, private sanitizer:DomSanitizer) {
    this.a = sanitizer.bypassSecurityTrustHtml("<stripe-pricing-table pricing-table-id=\"prctbl_1O5t4pFVWl0DTIsQTdu4WDnN\"\n" +
        "                      publishable-key=\"pk_test_51O4sGtFVWl0DTIsQ5JMGl2wctY0eXDxUKdUlUI8NtR3GLLzw9xwa6JCtFj5eKVlQVc0P1qubLQQicfkyx3onRyok00ZHKau6PV\">\n" +
        "</stripe-pricing-table>")
  }

  signup(){
    this.authService.logout({returnTo: document.location.origin});
  }
  login(){
    this.authService.loginWithRedirect({appState: {redirect: 'account'}});
  }
}
