import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EntryComponent, LogoutComponent, NotFoundComponent, RedirectComponent } from './index';

@NgModule({
  declarations: [
    EntryComponent,
    LogoutComponent,
    NotFoundComponent,
    RedirectComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    EntryComponent,
    LogoutComponent,
    NotFoundComponent,
    RedirectComponent
  ]
})
export class PagesModule {
}
