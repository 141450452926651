import { HttpParameterCodec } from '@angular/common/http';

class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return this.standardEncoding(k);
  }

  encodeValue(v: string): string {
    return this.standardEncoding(v);
  }

  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }

  decodeValue(v: string) {
    return decodeURIComponent(v);
  }

  standardEncoding(v: string): string {
    return encodeURIComponent(v);
  }
}

export const HTTP_URL_ENCODING_CODEC: HttpParameterCodec = new HttpUrlEncodingCodec();
