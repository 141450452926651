const apiGateway =  'http://localhost:8080';
import build from '../../.build.json';

export const environment = {
  production: false,
  api: 'api',
  appVersion: build.appVersion,
  stage: build.stage,
  version: 'v1',
  version2: 'v2',
  shopUrl: 'https://staging.checkout-backend.cydeo.com',
  baseUrl: apiGateway,
  accountBaseUrl: 'sso-accounts-service',
  programBaseUrl: 'programs-service',
  programZuul: 'zuul/programs-service',
  contentBaseUrl: 'zuul/contents-service',
  enrollmentBaseUrl: 'enrollments-service',
  assessmentBaseUrl: 'assessments-service',
  eventBaseUrl: 'events-service',
  feedbackBaseUrl: 'feedback-service',
  auth0: {
    domain: 'dev-djia38py.us.auth0.com',
    clientId: 'mRdP77A8TjbMGKiKXfCwctJMmcYoiMZk',
    audience: 'https://dev-lms-api/',
    useRefreshTokens: true,
    scope: 'openid profile email offline_access'
  },
  analytics: {
    gtm: {
      containerId: 'GTM-P57WLJ3',
    },
  /*  bugsnag: {
      key: 'fe7e5d843e4a2f9deaeb13ee44ef0703'
    }*/
  },
  privateKeyWord: 'uncopyrightable',
  tinyMCEApiKey: 'cwc3gdwunkrme5tysnzq4jyjasrfs8di4qiac0irlnj2l19m',
  roleKey: 'https://lms-api/authorities',
  memberApp: document.location.origin
};
