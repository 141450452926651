import {APP_INITIALIZER, NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { DatePipe } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { environment } from "@environment";
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
// Modules
import {AppRoutingModule} from './app-routing.module';
import {NgSelectModule} from "@ng-select/ng-select";
import {PagesModule} from './shared/pages/pages.module';
import {IconsComponent, ToastModule} from '@components';
// Services
// Components
import {AppComponent} from './app.component';
import {AppInitService} from './shared/services/app-init.service';
import { GuestComponent } from './guest/guest.component';
import { ShopComponent } from './shared/components/shop/shop.component';
import { LicenceAgreementComponent } from './shared/components/licence-agreement/licence-agreement.component';

export const errorHandlerFactory = () => new BugsnagErrorHandler();

export function initApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}

@NgModule({
    declarations: [
        AppComponent,
        IconsComponent,
        GuestComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        NgSelectModule,
        AppRoutingModule,
        AuthModule.forRoot({
            ...environment.auth0,
            httpInterceptor: {
                allowedList: [
                    environment.baseUrl,
                    environment.shopUrl,
                    environment.baseUrl + '/*',
                ]
            }
        }),
        PagesModule,
        ToastModule,
        ShopComponent
    ],
    providers: [
        DatePipe
    ],
    bootstrap: [AppComponent]

})
export class AppModule {
}
