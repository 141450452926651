<div class="modal-header">
  <h5 class="modal-title">
    {{ removeLabel ? 'Remove' : 'Delete'}} action
  </h5>
  <button (click)="activeModal.close('close')" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  <p class="mb-0 text-break">Are you sure you want to <span class="text-lowercase">{{ removeLabel ? 'remove' : 'delete'}}</span>&nbsp;<span
    class="fw-bold">{{ message }}</span>?</p>
</div>

<div class="modal-footer">
  <button (click)="activeModal.close('close')" class="btn btn-light me-2">
    Cancel
  </button>
  <button (click)="activeModal.close(true)" class="btn btn-danger">
    {{ removeLabel ? 'Remove' : 'Delete'}}
  </button>
</div>
