export const moveItemTopOrBottom = (items: any[], fromIndex: number, position: 'top' | 'bottom' = 'top'): any[] => {
  const item = items.splice(fromIndex, 1)[0];
  items[position === 'top' ? 'unshift' : 'push'](item);

  return items;
}

export const swapItems = (items: any[], pos1: number, pos2: number): any[] => {
  const temp = items[pos1];
  items[pos1] = items[pos2];
  items[pos2] = temp;
  return items;
}
