import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-quiz-modal',
  templateUrl: './quiz-modal.component.html',
  styleUrls: ['./quiz-modal.component.scss']
})
export class QuizModalComponent implements OnInit {
  @Input() identifier: any;
  questionText = '';
  questions:any = [];
  questionType = '';
  quizForm: FormGroup;
  submitted = false;
  result: any = null;
  formCreated = false;
  @Input() set question(param: any) {
    if (param) {
      this.questionText = param.videoQuestionText;
      this.questions = param.answers;
      this.questionType = param.videoQuestionType;
      console.log('param', param);
      this.submitted = false;
      this.result = null;
      this.createForm(this.questions, this.questionType);
    }
  }
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.quizForm = new FormGroup({
    });
  }

  ngOnInit(): void {
  }
  submitAnswer(){
    const userAnswers = this.quizForm.getRawValue();
    const questionAnswers:any = this.questions;
    if(this.questionType !== 'MULTIPLE_ANSWER'){
      let correctAnswer;
      for (const question of questionAnswers){
        if (question.isCorrect){
          correctAnswer = question.videoQuestionAnswerId;
        }
      }
      this.result = userAnswers.radioAnswer === correctAnswer;
      this.quizForm.controls['radioAnswer'].disable();
    } else {
      const allQuestionsCorrect = questionAnswers.every((question: any) => {
        for (let qa of questionAnswers){
          this.quizForm.controls[qa.videoQuestionAnswerId].disable();
        }
        const userAnswer = userAnswers[question.videoQuestionAnswerId.toString()];
        if (question.isCorrect) {
          return userAnswer === true;
        } else {
          return userAnswer === '' || userAnswer === false;
        }
      });
      this.result = allQuestionsCorrect;
    }

    this.submitted = true;
  }
  createForm(questions:any, type: string) {
    for (const question of questions){
      if (type !== 'MULTIPLE_ANSWER'){
        this.quizForm.addControl('radioAnswer', new FormControl(false));
      } else {
        this.quizForm.addControl(question.videoQuestionAnswerId, new FormControl(false));
      }

    }
    this.formCreated = true;
  }
  submitModal(){
    this.activeModal.close();
  }
}
