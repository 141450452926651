export const getQuestionTypeLabel = (questionType: string): string => {

  switch (questionType) {
    case 'multipleChoice':
      return 'Multiple Choice';
    case 'multipleAnswer':
      return 'Multiple Answer';
    case 'trueFalse':
      return 'True/False';
    case 'singleBlank':
      return 'Fill in the blank';
    case 'multipleBlanks':
      return 'Fill in multiple blanks';
    case 'multipleDropdown':
      return 'Multiple Drop-Down';
  }

  return '';
}
