<div class="shop-dashboard-header">
        <h2>Cyber security operations program aplication</h2>
</div>
<div class="shop-dashboard-container">
        <div class="wrapper">
                <ol class="c-timeline">
                        <li class="c-timeline__item content-1" [ngClass]="step > 1 ? 'step-done' : 'step-active'">
                                <div class="c-timeline__content " >

                                </div>
                                <p class="circle-title">Start Application</p>
                        </li>
                        <li class="c-timeline__item content-2"  [ngClass]="{'step-done': step > 2, 'step-active' : step === 2, 'step-default' : step < 2 }">
                                <div class="c-timeline__content " >

                                </div>
                                <p class="circle-title">Personal Details</p>
                        </li>
                        <li class="c-timeline__item content-3" [ngClass]="{'step-done': step > 3, 'step-active' : step === 3, 'step-default' : step < 3 }">
                                <div class="c-timeline__content ">

                                </div>
                                <p class="circle-title">Agreement</p>
                        </li>
                        <li class="c-timeline__item content-4" [ngClass]="{'step-done': step > 4, 'step-active' : step === 4, 'step-default' : step < 4 }">
                          <div class="c-timeline__content">

                                </div>
                                <time>Payment</time>
                        </li>
                        <li class="c-timeline__item content-5" [ngClass]="{'step-done': step > 5, 'step-active' : step === 5, 'step-default' : step < 5 }">
                                <div class="c-timeline__content">

                                </div>
                                <time>Onboarding</time>
                        </li>
                </ol>
        </div>
        <div class="content">
                <div class="step1" *ngIf="step === 1">
                   <div class="col-7" style="display: flex; flex-direction: column">
                        <h4>Welcome to your guide to the SOC Training & Practice Program Application!</h4>
                        <br>
                        <p style="font-size: 16px; font-weight: 600">What is SOC training program?</p>
                        <p>
                                Security Operation Center (SOC) Training & Practice Program is designed to provide <br>training and practice opportunity to any individual who has basic level of understating of<br> Cyber Security and Networking. The program has two prerequisites:
                             <br>   - Network Fundamentals
                             <br>   - Security+ Concepts
                        </p>
                        <p style="display: flex; flex-direction: column; gap:10px;">
                               <span> <a style="color:#ffa639;">Start Date:</a> November 27th, 2023</span>

                                <span> <a style="color:#ffa639">End Date:</a> December 22nd, 2023</span>
                        </p>
                        <p style="font-size: 16px; font-weight: 600; margin-top: 30px; display: flex">
                                How to Apply?
                        </p>
                        <p>
                                1. <b>Start:</b> Once you get a clear overview of the application process, click Start. <br>
                                2. <b>Personal Details: </b>Fill out your personal details. <br>
                                3. <b>Program Agreement: </b>Go through and accept our terms and conditions.<br>
                                4. <b>Payment Plan:</b> Select the option that works best for you. <br>
                                5. <b>Onboarding:</b> After completing the application, you’ll receive detailed instructions for the next steps.
                        </p>
                        <h5 style="margin-top: 35px;">Ready to elevate your Cyber Security skills? Start your application journey now!</h5>
                        <button style=" display:flex; align-self: center; margin-top: 30px; padding: 10px 40px;" (click)="nextStep()" class="btn btn-primary"> Start</button>
                   </div>
                </div>
                <div class="step2" *ngIf="step === 2">
                        <form [formGroup]="form" (ngSubmit)="submitForm()">
                                <div class="form-row">
                                        <div class="col-md-3">
                                                <div class="form-group">
                                                        <label>First Name</label>
                                                        <input type="text" class="form-control" formControlName="firstName" placeholder="First name">
                                                </div>
                                        </div>
                                        <div class="col-md-3">
                                                <div class="form-group">
                                                        <label>Last Name</label>
                                                        <input type="text" formControlName="lastName" class="form-control" placeholder="Last name">
                                                </div>
                                        </div>
                                </div>
                                <div class="form-row">
                                        <div class="col-3">
                                                <label>Birthdate</label>
                                                <div class="input-group birthdate-input">
                                                        <input
                                                                class="form-control"
                                                                placeholder="yyyy-mm-dd"
                                                                formControlName="birthdate"
                                                                name="dp"
                                                                [(ngModel)]="model"
                                                                ngbDatepicker
                                                                #d="ngbDatepicker"
                                                        />
                                                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
                                                </div>
                                        </div>
                                </div>
                                <div class="form-row">
                                        <div class="form-group">
                                                <label> Gender
                                                </label>
                                                <div class="custom-control custom-radio">
                                                        <input type="radio" id="customRadio1" value="male" formControlName="gender" name="gender" class="custom-control-input">
                                                        <label class="custom-control-label" for="customRadio1">Male</label>
                                                </div>
                                                <div class="custom-control custom-radio">
                                                        <input type="radio" id="customRadio2" value="female" formControlName="gender" name="gender" class="custom-control-input">
                                                        <label class="custom-control-label" for="customRadio2">Female</label>
                                                </div>
                                        </div>
                                </div>
                                <div class="form-row">
                                        <div class="col-md-3">
                                                <div class="form-group">
                                                        <label>Email Address</label>
                                                        <input type="email" formControlName="email" class="form-control" placeholder="Email">
                                                </div>
                                        </div>
                                        <div class="col-md-3">
                                                <div class="form-group">
                                                        <label>Phone</label>
                                                        <input type="text" formControlName="phoneNumber" class="form-control" placeholder="Phone">
                                                </div>
                                        </div>
                                </div>
                          <!-- /////// -->
                          <div formGroupName="address">
                                <div class="form-row">
                                        <div class="col-md-6 custom-width">
                                                <div class="form-group">
                                                        <label>Street</label>
                                                        <input type="text" formControlName="street" class="form-control" placeholder="Street">
                                                </div>
                                        </div>

                                </div>
                                <div class="form-row">
                                        <div class="col-md-3">
                                                <div class="form-group">
                                                        <label>State/Province/Region</label>
                                                        <input type="email" formControlName="state" class="form-control" placeholder="State/Province/Region">
                                                </div>
                                        </div>
                                        <div class="col-md-3">
                                                <div class="form-group">
                                                        <label>Zip Code</label>
                                                        <input type="text" formControlName="zipCode" class="form-control" placeholder="Zip Code">
                                                </div>
                                        </div>
                                </div>
                                <div class="form-row">
                                        <div class="col-md-3">
                                                <div class="form-group">
                                                        <label>Country</label>
                                                      <!--  <input type="text" class="form-control" placeholder="Country">-->
                                                        <select class="form-select" formControlName="country" aria-label="Default select example">
                                                                <option value="United States" selected>United States</option>
                                                                <option *ngFor="let country of countries"  [value]="country.name">{{country.name}}</option>
                                                        </select>
                                                </div>
                                        </div>
                                  <div class="col-md-3">
                                    <div class="form-group">
                                      <label>City</label>
                                      <input type="email" formControlName="city" class="form-control" placeholder="City">
                                    </div>
                                  </div>
                                </div>
                          </div>
                                <div class="form-row">
                                        <div class="col-md-6 custom-width">
                                                <div class="form-group">
                                                        <label>How did you hear about us?</label>
                                                        <select class="form-select"  aria-label="Default select example">
                                                                <option selected>Select One</option>
                                                                <option value="test">Email</option>
                                                                <option value="test">Facebook</option>
                                                                <option value="test">Google</option>
                                                                <option value="test">Instagram</option>
                                                                <option value="test">LinkedIN</option>
                                                                <option value="test">Twitter</option>
                                                                <option value="test">Referred by a friend or colleague</option>
                                                                <option value="test">Other</option>
                                                        </select>
                                                </div>
                                        </div>

                                </div>
                                <div class="step-footer col-6 ">
                                        <button  (click)="prevStep()" class="btn btn-primary btn-back "
                                                type="button">Back
                                        </button>
                                        <button  type="submit"  class="btn btn-primary ">Continue
                                        </button>
                                </div>
                        </form>

                </div>
                <div class="step3" *ngIf="step === 3">
                        <h4 class="c-timeline__title">Program-Prerequisites</h4>
                        <div class="custom-control custom-checkbox terms-conditions">
                                <input type="checkbox" [(ngModel)]="prerequisites" class="custom-control-input" id="customCheck1">
                                <label style="color: #707070" class="custom-control-label" >I acknowledge and confirm that I have satisfied these program prerequisites : <br> - Proficiency in Network Fundamentals <br> - Proficiency in Security Concepts</label>
                        </div>
                        <br>
                        <h4 class="c-timeline__title" style="margin-top:35px;">Agreement</h4>
                        <div class="col-8" style="max-height: 330px; overflow-y: scroll; border-right: 1px solid #707070; padding: 10px; margin-top: 15px; margin-bottom: 15px; ">
                              <app-licence-agreement></app-licence-agreement>
                        </div>

                        <div class="custom-control custom-checkbox">
                                <input type="checkbox"  [(ngModel)]="termsConditions" class="custom-control-input" id="agreeTermsCon">
                                <label class="custom-control-label" for="agreeTermsCon">I have read and agree to the Privacy Policy</label>
                        </div>

                        <div class="col-4">
                                <br>
                                <div class="form-group">
                                        <label>Type your full legal name</label>
                                        <input type="text"  [(ngModel)]="fullLegalName" class="form-control" placeholder="Name">
                                </div>
                        </div>
                        <br>
                        <div class="step-footer col-6 ">
                                <button (click)="prevStep()" class="btn btn-primary btn-back "
                                        type="button">Back
                                </button>
                                <button (click)="nextStep(3)" class="btn btn-primary "
                                        type="button">Continue
                                </button>
                        </div>
                </div>
                <div class="step4" *ngIf="step === 4">
                        <div class="col-6">
                                <div [innerHTML]="a"></div>
                        </div>
                </div>
                <div class="step4" *ngIf="step === 5">
                        <h2>Payment completed.</h2>
                        <p style="font-size: 22px;">
                                Thank you for your payment. Click here to go your receipt. You will also get an email with receipt.
                        </p>
                        <h2>Keen an eye on your inbox.</h2>
                        <p style="font-size: 22px;">
                                You will get an email with instructions to finalize setting up your account. Once you confirm your email, you will be able access your  learning content.<br><br>
                                You will also get an email with further instructions on how to join other learning applications used in the course. <br><br>
                                We are excited we started this journey together.<br>
                                You can now close this page.
                        </p>
                </div>
        </div>
</div>
