import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export const convertSecondsToHMS = (seconds: number): NgbTimeStruct => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  return {
    hour: hours,
    minute: minutes,
    second: remainingSeconds
  };
}
