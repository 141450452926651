<div class="shop-header">
    <div class="shop-header-container">
        <img style="max-width: 116px;" [src]="logo.src" >
        <div class="shop-header-container-buttons">

        </div>
    </div>
</div>
<app-shop></app-shop>
<router-outlet></router-outlet>
