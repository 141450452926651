import { Component, OnInit } from '@angular/core';
import { AuthService } from "@auth0/auth0-angular";
import { UserService } from '@services';
import { Role } from '@enums';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService,
              private userService: UserService) {
  }

  ngOnInit(): void {

    if (this.userService.getRole() === Role.USER) {
      localStorage.removeItem('viewAsStudent');
      localStorage.removeItem('programSlug');
      localStorage.removeItem('courseSlug');
    }

    this.authService.logout({returnTo: document.location.origin});
  }
}
