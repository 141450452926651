import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountGuard } from '@guards';
import { EntryComponent, NotFoundComponent, RedirectComponent } from '@pages';

import {NoAuthGuard} from "./shared/guards/noAuth.guard";

const routes: Routes = [

  {
    path: 'redirect',
    component: RedirectComponent
  },
/*  {
    path: 'guest',
    component: GuestComponent
  },*/
  {
    path: 'security-operation-center',
    loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule),
    //title: General.pageTitle(Module.PROGRAMS)

  },
  {
    path: '',
    pathMatch: 'full',
    component: EntryComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
