import {ActivatedRoute, Router, UrlSegment} from "@angular/router";

export const goBack = (url: string[],
                       router: Router,
                       activatedRoute: ActivatedRoute): Promise<boolean> => {
  return router.navigate(url, {relativeTo: activatedRoute});
}

export const getUrlFromSegments = (segments: UrlSegment[]): string => {
  return segments.reduce((path, currentSegment) => {
    return `${path}/${currentSegment.path}`;
  }, '');
}
