import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "@environment";
import { Response, User } from '@models';
import { GatewayService } from './index';
import { BehaviorSubject, map } from "rxjs";
import { getActiveModule, getRole } from "@utils";

@Injectable({
  providedIn: 'root'
})
export class UserService extends GatewayService<Response<User>> {

  private userBehavior: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  user$ = this.userBehavior.asObservable();

  constructor(http: HttpClient) {
    super(http);
    this.endpoint = `${environment.accountBaseUrl}/${environment.api}/${environment.version}/users`;
  }

  setUser(user: User | null) {
    this.userBehavior.next(user);
  }

  updateFirstDay(body: { calendarFirstDay: string }) {
    // @ts-ignore
    return this.update({endpoint: `me/preferences/calendar`, body})
  }


  getUserValue(): User | null {
    return this.userBehavior.value;
  }

  getActiveUser() {
    return this.read({endpoint: 'me'}).pipe(
      map(response => {
        this.setUser(response.data as User);
        return response;
      })
    );
  }

  getRole(): string | null {
    const user = this.getUserValue();

    if (!user) return null;

    const roles = (user.roles || []).map(role => role.roleName) as string[];
    return getRole(roles);
  }

  getActiveModule(): string | null {
    const role = this.getRole();

    if (!role) return null;

    return getActiveModule(role);
  }
}
