import {Injectable} from '@angular/core';
import {GatewayService} from "../gateway.service";
import {Content, Response} from "@models";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environment";

@Injectable({
  providedIn: 'root'
})
export class ContentService extends GatewayService<Response<Content>> {

  constructor(http: HttpClient) {
    super(http);

    this.endpoint = `${environment.programBaseUrl}/${environment.api}/${environment.version}/contents`;
  }
}
