import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pagination} from "@models";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() pagination: Pagination = new Pagination();
  @Input() entries: number[] = [10, 20, 50, 100];
  @Input() hideEntries: boolean = false;
  @Input() rotate: boolean = true;

  @Output() paginationChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setEntry(entry: number): void {
    const {pagination} = this;

    pagination.page = 1;
    pagination.size = entry;

    this.paginationChange.emit(pagination);
  }

  setPage(page: number): void {
    const {pagination} = this;

    pagination.page = page;

    this.paginationChange.emit(pagination);
  }
}
