import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  standalone: true
})
export class SvgComponent implements OnInit {

  @Input() width: number | string = 18;
  @Input() height: number | string = 18;
  @Input() name: string | undefined;
  @Input() svgClass: string | string[] = '';

  constructor() { }

  ngOnInit(): void {
  }

}
