import {Pagination} from "./pagination";
import {Sort} from "./sort";
import {DropdownFilter} from "./dropdown-filter";

export class State {
  search: string = '';
  pagination: Pagination = new Pagination();
  sort: Sort = {};
  filter: DropdownFilter[] = [];

  reset(): void {
    this.search = '';
    this.pagination = new Pagination();
    this.sort = {};
    this.filter = [];
  }
}
