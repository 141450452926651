import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-licence-agreement',
  templateUrl: './licence-agreement.component.html',
  styleUrls: ['./licence-agreement.component.scss']
})
export class LicenceAgreementComponent {

}
