<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classes ? toast.classes : 'bg-primary'"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
>
  <div class="d-flex">

    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
      {{ toast.textOrTpl }}
    </ng-template>

    <button type="button"
            class="btn-close btn-close-white me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close" (click)="toastService.remove(toast)">
    </button>
  </div>
</ngb-toast>
