import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@services';

@Directive({
  selector: '[ifRoles]',
  standalone: true
})
export class IfRolesDirective implements OnInit {

  @Input() public ifRoles: string[] = [];

  constructor(private userService: UserService,
              private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    const role = this.userService.getRole();

    if (role && this.ifRoles.indexOf(role) > -1) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}

