<div *ngIf="pagination.totalElements" [class]="hideEntries ? 'justify-content-end' : 'justify-content-between'"
     class="d-flex align-items-center mt-3">
  <div *ngIf="!hideEntries" class="d-flex align-items-center">
    <label class="me-2" for="show">Show</label>
    <ng-select
      (ngModelChange)="setEntry($event)"
      [clearable]="false"
      [items]="entries"
      [ngModel]="pagination.size"
      id="show">
    </ng-select>
  </div>

  <div>
    <ngb-pagination
      (pageChange)="setPage($event)"
      [(page)]="pagination.page"
      [collectionSize]="pagination.totalElements"
      [maxSize]="5"
      [pageSize]="pagination.size"
      [rotate]="rotate"
      aria-label="Pagination">
      <ng-template ngbPaginationPrevious>&#60;</ng-template>
      <ng-template ngbPaginationNext>&#62;</ng-template>
      <ng-template let-page ngbPaginationNumber>{{ page }}</ng-template>
    </ngb-pagination>
  </div>
</div>
