import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const Regex = {
  ALPHABETIC: '^[a-zA-Z ]*$',
  ALPHABETIC_NUMBERS_CONTAIN_PERIOD_OR_UNDERSCORE: '^[A-Za-z0-9]+(\\.|\\_?)[A-Za-z0-9]+?$',
  EMAIL: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  PASSWORD: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\da-zA-Z])(.+)',
  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 20,
  URL: '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})',
  LOWERCASE: /(?=.*[a-z])/,
  UPPERCASE: /(?=.*[A-Z])/,
  NUMBER: /(?=.*\d)/,
  SPECIAL_CHARACTERS: /^.*[^a-zA-Z0-9].*$/,
  REMOVE_TAGS_WITH_ATTRIBUTES: /<\/?[^>]+(>|$)/g
};

export const CustomValidators: {
  empty: ValidatorFn
} = {
  empty: (control: AbstractControl): ValidationErrors | null => {
    if (!control) {
      return null;
    }

    const {value} = control;

    return !(value && value.trim().length) ? {isEmpty: true} : null
  }
};
