<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>

</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="modal-footer">
  <button (click)="decline()" class=" btn btn-outline-danger " type="button">{{ btnCancelText }}</button>
  <button (click)="accept()" class="btn btn-danger" type="button">{{ btnOkText }}</button>
</div>
