import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { environment } from "@environment";
import { getActiveModule, getRole } from "@utils";
import { Role } from '@enums';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
  url?: string;

  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit(): void {
    console.log('Account: Entry');

    this.authService.appState$.subscribe(({redirect}) => {
      console.log('redirect', redirect);
      if (redirect) {
        this.url = redirect;
      }
    });

    this.authService.user$.subscribe(user => {
      if (user) {
        console.log('user', user);
        this.redirectToCurrentModule(user);
      } else {
        console.log('guest');
        this.router.navigateByUrl('security-operation-center');
        //this.authService.loginWithRedirect({appState: {redirect: this.url}})
      }
    });
  }

  redirectToCurrentModule(user: any): void {
    if (!user || !user[environment.roleKey]) {
      throw new Error('Something went wrong...');
    }

    const role = getRole(user[environment.roleKey]);
    const url = !this.url || this.url === '/' ? ('/' + getActiveModule(role)) : this.url;

    console.log('Account redirect:', url, this.url);

  //  if (role === Role.USER && url.indexOf('/member') > -1) {
   //   window.location.href = `${environment.memberApp}${url}`;
    //} else {
      this.router.navigateByUrl(url as string);
  //  }
  }
}
