import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from './safe/safe.pipe';
import { JoinPipe } from "./join/join.pipes";
import { TypeofPipe } from './typeof/typeof.pipe';
import { BytesPipe } from './bytes/bytes.pipe';
import { DurationPipe } from './duration/duration.pipe';
import { DateAgoPipe } from './date-ago/date-ago.pipe';
import { TimePipe } from './time/time.pipe';

@NgModule({
  declarations: [
    SafePipe,
    JoinPipe,
   // MimePipe,
    TypeofPipe,
    BytesPipe,
    DurationPipe,
    DateAgoPipe,
    TimePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    JoinPipe,
   // MimePipe,
    TypeofPipe,
    BytesPipe,
    DurationPipe,
    DateAgoPipe,
    TimePipe
  ]
})
export class PipesModule {
}
