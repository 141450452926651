import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from "@ng-select/ng-select";
import {SvgComponent} from "../svg/svg.component";

import {FiltersComponent} from './filters.component';

@NgModule({
  declarations: [
    FiltersComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbTooltipModule,
    NgSelectModule,
    SvgComponent
  ],
  exports: [
    FiltersComponent
  ]
})
export class FiltersModule {
}
