export class Pagination {
  page: number = 1;
  size: number = 20;
  totalElements: number = 0;
  totalPages: number = 0;
  firstPage: any;
  lastPage: any;
  nextPage: any;
  previousPage: any;
}
