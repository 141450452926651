export class CheckboxStatus {
  constructor(private context$: string,
              private checkboxIds$: number[] = []) {
  }

  get context(): string {
    return this.context$;
  }

  get checkboxIds(): number[] {
    return this.checkboxIds$;
  }

  isCheckboxSelect(target: any): boolean {
    return !!this.checkboxIds.find(id => id === target[this.context + 'Id']);
  }

  toggleCheckbox(target: any): void {
    const targetId = target[this.context + 'Id'];
    const index = this.checkboxIds.findIndex(id => id === targetId);

    if (index > -1) {
      this.checkboxIds.splice(index, 1);
    } else {
      this.checkboxIds.push(targetId);
    }
  }

  toggleCheckboxes(target: any[], checked: boolean): void {
    this.checkboxIds$ = checked ? target.map(currentTarget => currentTarget[this.context + 'Id']) : [];
  }

  reset() {
    this.checkboxIds$ = [];
  }

  size(): number {
    return this.checkboxIds.length;
  }
}
