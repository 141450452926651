import { Column } from "./column";

export abstract class Cell {
  protected constructor(
    private row$: any,
    private column$: Column,
    private options$: any
  ) {
  }

  get row(): any {
    return this.row$;
  }

  get column(): Column {
    return this.column$;
  }

  get options(): any {
    return this.options$;
  }
}
