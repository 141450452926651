import {State} from "@models";

export const isEmpty = (arg: any): boolean => {
  if (!arg) {
    return true;
  }

  if (typeof arg === 'string') {
    return !arg.trim().length;
  }

  if (Array.isArray(arg)) {
    return !arg.length;
  }

  return !Object.keys(arg).length;
}

export const appendQueryParams = (endpoint: string, includes: any[] | undefined): string => {

  if (includes && includes.length > 0) {
    includes.forEach((include, index) => {

      endpoint = endpoint + (index === 0 ? '?' : '&') + include;
    });
  }

  return endpoint;
};

export const buildQuery = (state: State): { [key: string]: string[] } => {
  const params: any = {};

  if (isEmpty(state)) {
    return params;
  }

  Object.keys(state).forEach(stateKey => {
    switch (stateKey) {
      case 'pagination':
        const {page, size} = state[stateKey];
        params['page'] = page;
        params['size'] = size;
        break;
      case 'search':
        if (!isEmpty(state[stateKey])) {
          params[stateKey] = state[stateKey];
        }
        break;
      case 'sort':
        params[stateKey] = [];
        Object.keys(state[stateKey]).forEach(sortKey => {
          params[stateKey].push(sortKey + ',' + state[stateKey][sortKey]);
        });
        break;
      case 'filter':
        params[stateKey] = [];

        state[stateKey].forEach(({id, operator, selected}) => {
          if (selected.id) {
            const filter = `${id}:${operator}:${selected.id}`;
            params[stateKey].push(filter);
          }
        });
        break;
    }
  });

  return params;
};

export const getIdFromSlug = (slug: string): string => {
  if (isEmpty(slug)) {
    return '';
  }

  const splitSlug = slug.split('-');

  return splitSlug[0];
}
