import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {GatewayService} from "../gateway.service";
import {Course, Response} from "@models";
import {environment} from "@environment";
import {appendQueryParams} from '@utils';

@Injectable({
  providedIn: 'root'
})
export class CourseService extends GatewayService<Response<any>> {

  private courseBehavior: BehaviorSubject<Course | null> = new BehaviorSubject<Course | null>(null);
  course$ = this.courseBehavior.asObservable();

  constructor(http: HttpClient) {
    super(http);

    this.endpoint = `${environment.programBaseUrl}/${environment.api}/${environment.version}/courses`;
  }

  set course(course: Course | null) {
    this.courseBehavior.next(course);
  }

  getCourseModules(courseId: any, params: any) {
    return this.read({endpoint: `${courseId}/`, params})
  }

  getAllCourses<T>(includes?: any[], headers?: any) {

    const endpoint = appendQueryParams(this.endpoint, includes);

    return this.http.get<T[]>(endpoint, headers);
  }


  getSingleCourse(courseId: any, params: any) {
    return this.read({endpoint: `${courseId}`, params})
  }

}
