<!-- Show loader -->
<ng-template [ngIf]="loading">
  <div class="w-100 vh-100 d-flex align-items-center justify-content-center overflow-hidden">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template [ngIf]="!loading">
  <div class="not-found text-center vw-100 vh-100 d-flex flex-column">
    <!-- Header -->
    <header class="not-found-header p-2 p-lg-3 mb-3">
      <a routerLink="/">
        <img [alt]="logo.title" [src]="logo.src" class="img-fluid m-auto not-found-logo">
      </a>
    </header>

    <!-- Content -->
    <div class="not-found-content">
      <!-- Astronaut -->
      <div class="not-found-icon w-100 m-auto mt-3 mb-3">
        <img [src]="notFoundLogo" alt="Not found" class="img-fluid">
      </div>

      <!-- Footer -->
      <footer class="not-found-footer p-2 p-lg-1 pt-lg-3">
        <h3 class="text-uppercase mb-3">Page not found</h3>

        <a class="fw-semibold" href="/">Take me to the home page</a>
      </footer>
    </div>
  </div>
</ng-template>
